import staticContent from '@/components/static-content/index.vue';
import emptyData from "@/components/empty-data/index.vue"
import product from "@/components/product/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name:'ingredient',
  data(){
    return{
    }
  },
  components:{
    staticContent,
    emptyData,
    product
  },
  created() {
    this.fetchIngredientData(this.$route.params.slug);
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  computed: {
    ...mapGetters({
      ingredientData: 'ingredients/ingredientData',
      isLoadingIngredient: 'ingredients/isLoadingIngredient',
    }),
  },
  methods: {
    ...mapActions({
      fetchIngredientData: `ingredients/INGREDIENT_DATA_GET`,
    }),
  },
}
